.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.error::first-letter {
    /* text-transform: uppercase !important; */
    color: red !important;
    /* text-transform: lowercase !important; */
    margin-top: 5px;
}

.error {
    color: #d33232 !important;
    margin-top: 2px;
    font-size: 12px !important;
    padding: 0px 5px;
    /* background: #ffd1d199;
    border: 1px solid #e56666;
    display: block;
    position: relative; */
}


/* .error::before {
    content: '';
    position: absolute;
    z-index: 99;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid #e56666;
    top: -7px;
} */

span.error:first-letter {
    text-transform: uppercase !important;
}

.wraptextintable {
    white-space: 'pre-wrap';
    overflow-wrap: 'break-word'
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.box {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr auto 50px 50px 50px;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.box .btn-box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.6rem
}

.box input {
    width: calc(100% - 15px);
    display: block;
    position: relative;
}

.img-preview {
    overflow: hidden;
}

.star {
    color: red
}

.no-data {
    text-align: center;
    display: grid;
    padding: 10px;
    width: 100%;
}

.scroll-auto {
    max-height: 500px;
    overflow-y: auto;
}

.add-owner-logo img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
}

.scroll-auto .form-check {
    box-shadow: 0px 0px 2px rgb(0 0 0 / 65%);
    padding: 1px 35px 0px 10px;
    border-radius: 4px;
    min-height: 50px;
    line-height: 50px;
}

.scroll-auto .form-check:hover {
    box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
}

.scroll-auto .form-check .form-check-input {
    margin: -3px 10px 0px 0px;
}

.add-driver-logo img {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    margin-top: -5px;
}

.image-upload>input {
    visibility: hidden;
    width: 0;
    height: 0
}

.image-upload>label {
    margin-bottom: 0px;
}

.react-datepicker__input-container input {
    background-color: var(--input-color);
    max-height: 40px;
    line-height: 40px;
    border-radius: 4px;
    padding: 0.4375rem 0.75rem;
    border: 1px solid #d8dada !important;
    outline: none !important;
    color: #222;
    max-width: 110px;
}


/* For pdf */

.react-pdf__Page {
    margin-top: 10px;
}

.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}

.react-pdf__Document {
    width: 100%;
}

.visually-hidden {
    display: none !important;
}

div.outer-div {
    height: 170px;
    width: 300px;
    background-color: lightgray;
}

div.middle-div {
    text-align: center;
}

.doc-added-pdf {
    border: 1px dashed #d2cece;
    background: #f4f4f4;
    padding: 10px 3px !important;
    margin-top: 5px;
    border-radius: 6px;
}


/* Absolute Center Spinner */

.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}


/* Transparent Overlay */

.loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(250, 245, 245);
}


/* :not(:required) hides these rules from IE9 and below */

.loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(2, 46, 95, 1) 1.5em 0 0 0, rgba(2, 46, 95, 1) 1.1em 1.1em 0 0, rgba(2, 46, 95, 1) 0 1.5em 0 0, rgba(2, 46, 95, 1) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0, rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(2, 46, 95, 1) 0 -1.5em 0 0, rgba(2, 46, 95, 1) 1.1em -1.1em 0 0;
    box-shadow: rgba(2, 46, 95, 1) 1.5em 0 0 0, rgba(2, 46, 95, 1) 1.1em 1.1em 0 0, rgba(2, 46, 95, 1) 0 1.5em 0 0, rgba(2, 46, 95, 1) -1.1em 1.1em 0 0, rgba(2, 46, 95, 1) -1.5em 0 0 0, rgba(2, 46, 95, 1) -1.1em -1.1em 0 0, rgba(2, 46, 95, 1) 0 -1.5em 0 0, rgba(2, 46, 95, 1) 1.1em -1.1em 0 0;
}


/* Animation */

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-wrapper {
    position: fixed;
    z-index: 1090;
    height: 100vh;
    width: 100vw;
    background-color: transparent;
}

.truck-wrapper {
    height: 150px;
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    animation: bg 0.5s linear infinite;
    /* border-bottom:3px solid #404143; */
    overflow: hidden;
}

.formsubmit {
    background: rgba(0, 0, 0, 0.3) !important;
}

.formsubmit .spinner-wrap {
    background-color: #fff;
    box-shadow: 1px 1px 22px 1px rgb(0 0 0 / 30%);
    transform: scale(0.8);
}

.truck-wrapper-formsubmit {
    height: 100px;
    padding: 10px 0px;
    width: 150px;
    position: absolute;
    margin-top: 18px;
    top: 53%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    animation: bg 0.5s linear infinite;
    /* border-bottom:3px solid #404143; */
    /* overflow: hidden; */
}

.truck-wrapper-formsubmit:after {
    content: 'Please wait...';
    /* content: 'Connecting to DotStow..'; */
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    bottom: -23px;
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #022e85;
    background: #022e85;
    background: -moz-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: -webkit-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: linear-gradient(to right, #022e85 0%, #022e85 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    color: white;
    padding-top: 15px;
    padding-bottom: 18px;
    animation: bg 3s linear infinite;
    margin-left: -75px;
    text-transform: capitalize !important;
}

.truck-wrapper-authentication {
    height: 100px;
    padding: 10px 0px;
    width: 150px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    animation: bg 0.5s linear infinite;
    /* border-bottom:3px solid #404143; */
    /* overflow: hidden; */
}

.truck-wrapper-authentication:after {
    content: 'Please wait...';
    /* content: 'Connecting to DotStow..'; */
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    bottom: -23px;
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #022e85;
    background: #022e85;
    background: -moz-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: -webkit-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: linear-gradient(to right, #022e85 0%, #022e85 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    color: white;
    padding-top: 15px;
    padding-bottom: 18px;
    animation: bg 3s linear infinite;
    margin-left: -75px;
    text-transform: capitalize !important;
}

.truck-wrapper:after {
    content: 'Connecting to DotStow..';
    font-size: 15px;
    line-height: 20px;
    position: absolute;
    bottom: -19px;
    position: absolute;
    bottom: 0px;
    text-align: center;
    width: 100%;
    border-top: 1px solid #022e85;
    background: #022e85;
    background: -moz-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: -webkit-linear-gradient(left, #022e85 0%, #022e85 100%);
    background: linear-gradient(to right, #022e85 0%, #022e85 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    color: white;
    padding-top: 7px;
    padding-bottom: 6px;
    animation: bg 3s linear infinite;
}

.truck {
    height: 110px;
    width: 150px;
    position: absolute;
    bottom: 48px;
    left: calc(50% + 10px);
    transform: translateX(-50%);
}

.truck>.truck-container {
    background: rgba(2, 46, 95, 1);
    background: -moz-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    background: linear-gradient(135deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#afbdc3', endColorstr='#8fa3ad', GradientType=1);
    height: 50px;
    width: 80px;
    position: absolute;
    top: 10px;
    left: 10px;
    animation: container 0.4s linear infinite;
}

.truck>.glases {
    background: rgb(40, 181, 245);
    background: -moz-linear-gradient(-45deg, rgba(40, 181, 245, 1) 0%, rgba(40, 181, 245, 1) 50%, rgba(2, 153, 227, 1) 52%, rgba(2, 153, 227, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(40, 181, 245, 1) 0%, rgba(40, 181, 245, 1) 50%, rgba(2, 153, 227, 1) 52%, rgba(2, 153, 227, 1) 100%);
    background: linear-gradient(135deg, rgba(40, 181, 245, 1) 0%, rgba(40, 181, 245, 1) 50%, rgba(2, 153, 227, 1) 52%, rgba(2, 153, 227, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    position: absolute;
    height: 25px;
    width: 25px;
    border: 4px solid #022e85;
    border-bottom: none;
    top: 35px;
    left: 95px;
    border-top-right-radius: 6px;
    animation: updown-half 0.4s linear infinite;
}

.truck>.glases:after {
    content: '';
    display: block;
    background-color: #022e85;
    height: 13px;
    width: 10px;
    position: absolute;
    right: -10px;
    bottom: 0px;
    border-radius: 10px / 15px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
}

.truck>.glases:before {
    content: '';
    display: block;
    background-color: #022e85;
    height: 12px;
    width: 16px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-top-right-radius: 4px;
}

.truck>.bonet {
    background-color: #022e85;
    position: absolute;
    width: 124px;
    height: 15px;
    top: 64px;
    left: 10px;
    z-index: -1;
    animation: updown 0.4s linear infinite;
}

.truck>.bonet:after {
    content: '';
    display: block;
    height: 10px;
    width: 6px;
    position: absolute;
    right: 0px;
    bottom: 2px;
    border-top-left-radius: 4px;
}

.truck>.base {
    position: absolute;
    background-color: #022e85;
    width: 106px;
    height: 15px;
    border-top-right-radius: 10px;
    top: 70px;
    left: 14px;
    animation: updown 0.4s linear infinite;
}

.truck>.base:before {
    content: '';
    display: block;
    background-color: #022e85;
    height: 12px;
    width: 5px;
    position: absolute;
    left: -4px;
    bottom: 0px;
    border-bottom-left-radius: 4px;
}

.truck>.base:after {
    content: '';
    display: block;
    background-color: #022e85;
    height: 10px;
    width: 20px;
    position: absolute;
    right: -16px;
    bottom: 0px;
    border-bottom-right-radius: 4px;
    z-index: -1;
}

.truck>.base-aux {
    width: 82px;
    height: 8px;
    background-color: #022e85;
    position: absolute;
    top: 65px;
    left: 14px;
    border-bottom-right-radius: 4px;
    animation: updown 0.4s linear infinite;
}

.truck>.wheel-back {
    left: 20px
}

.truck>.wheel-front {
    left: 95px;
}

.truck>.wheel-back,
.truck>.wheel-front {
    border-radius: 100%;
    position: absolute;
    background: rgba(2, 46, 95, 1);
    background: -moz-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 49%, rgba(2, 46, 95, 1) 52%, rgba(2, 46, 95, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 49%, rgba(2, 46, 95, 1) 52%, rgba(2, 46, 95, 1) 100%);
    background: linear-gradient(135deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 49%, rgba(2, 46, 95, 1) 52%, rgba(2, 46, 95, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    top: 80px;
    height: 22px;
    width: 22px;
    animation: spin 0.6s linear infinite;
}

.truck>.wheel-back:before,
.truck>.wheel-front:before {
    content: '';
    border-radius: 100%;
    left: 5px;
    top: 5px;
    position: absolute;
    background: rgba(2, 46, 95, 1);
    background: -moz-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    background: -webkit-linear-gradient(-45deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    background: linear-gradient(135deg, rgba(2, 46, 95, 1) 0%, rgba(2, 46, 95, 1) 50%, rgba(2, 46, 95, 1) 51%, rgba(2, 46, 95, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#022e85', endColorstr='#022e85', GradientType=1);
    height: 12px;
    width: 12px;
}

@keyframes spin {
    50% {
        top: 81px;
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes container {
    30% {
        transform: rotate(1deg);
    }
    50% {
        top: 11px;
    }
    70% {
        top: 10px;
        transform: rotate(-1deg);
    }
}

.truck>.smoke {
    position: absolute;
    background-color: rgba(2, 46, 95, 1);
    border-radius: 100%;
    width: 8px;
    height: 8px;
    top: 90px;
    left: 6px;
    animation: fade 0.4s linear infinite;
    opacity: 0;
}

.truck>.smoke:after {
    content: '';
    position: absolute;
    background-color: rgba(2, 46, 95, 1);
    border-radius: 100%;
    width: 6px;
    height: 6px;
    top: -4px;
    left: 4px;
}

.truck>.smoke:before {
    content: '';
    position: absolute;
    background-color: rgba(2, 46, 95, 1);
    border-radius: 100%;
    width: 4px;
    height: 4px;
    top: -2px;
    left: 0px;
}

@keyframes fade {
    30% {
        opacity: 0.3;
        left: 7px;
    }
    50% {
        opacity: 0.5;
        left: 6px;
    }
    70% {
        opacity: 0.1;
        left: 4px;
    }
    90% {
        opacity: 0.4;
        left: 2px;
    }
}

@keyframes bg {
    from {
        background-position-x: 0px;
    }
    to {
        background-position-x: -400px;
    }
}

@keyframes updown {
    50% {
        transform: translateY(-20%);
    }
    70% {
        transform: translateY(-10%);
    }
}

@keyframes updown-half {
    50% {
        transform: translateY(-10%);
    }
    70% {
        transform: translateY(-5%);
    }
}

.w-42 {
    width: 42px !important;
}

:root {
    --accent: hsl(230deg 100% 20%);
    --size: 2.5rem;
    --duration: 1s;
}

.spinner {
    width: var(--size);
    aspect-ratio: 1;
    display: grid;
    -webkit-mask: conic-gradient(from 22deg, #0001, #000);
    mask: conic-gradient(from 22deg, #0001, #000);
    -webkit-animation: spin var(--duration) steps(8) infinite;
    animation: spin var(--duration) steps(8) infinite;
}

.spinner,
.spinner:before {
    --gradient: linear-gradient(var(--accent) 0 0);
    background: var(--gradient) 50%/34% 8% space no-repeat, var(--gradient) 50%/8% 34% no-repeat space;
}

.spinner:before {
    content: "";
    transform: rotate(45deg);
}

@-webkit-keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}

.spinner-wrap {
    /* background-color: rgba(255, 255, 255, 0.9); */
    text-align: center;
    line-height: 100%;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 150px;
    height: 150px;
    border-radius: 4px;
    align-items: center;
    padding: 10px;
    text-transform: uppercase;
    margin: auto;
    /* box-shadow: 1px 1px 15px rgb(0 0 0 / 30%); */
}

.spin-body {
    width: calc(100% - 235px);
    height: 100vh;
    background: #e6e6e6;
    /* background: rgba(255, 255, 255, 0.2); */
    position: fixed;
    left: 235px;
    right: 0px;
    top: 0px;
    z-index: 1;
    display: grid;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

@media screen and (min-width: 320px) and (max-width: 991px) {
    .spin-body {
        width: calc(100% - 0px) !important;
        left: 0px;
    }
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 100%;
    height: 36px;
    padding: 10px 20px;
    font-weight: 400;
    border: 1px solid #ced4da;
    border-radius: 4px;
    margin-bottom: 6px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 40px;
    width: 100%;
    border: 1px solid #ced4da;
    font-weight: 400;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    line-height: 10px !important;
    max-height: 240px;
    overflow-y: auto;
    border-bottom-right-radius: 4px;
    z-index: 2;
    border-radius: 4px;
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.3);
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #1e90ff;
    color: #fff;
}

.swal2-styled.swal2-confirm {
    background-color: #022E5F !important;
}
.swal2-error
{
    top: 20px;
    position: relative;
}
.lableUpper {
    text-transform: uppercase !important;
}

.toggle-wrp {
    background: #c9c9c9;
    padding: 2px 0px 2px 2px;
    display: grid;
    border-radius: 25px;
    margin-right: 5px;
}

.toggle-switch {
    position: relative;
    margin-right: 2px;
    width: 100px;
    display: inline-block;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    text-align: left;
}

.toggle-switch-checkbox {
    display: none;
}

.toggle-switch-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid rgb(223, 213, 213);
    border-radius: 20px;
    margin: 0;
}

.toggle-switch-label:focus {
    outline: none;
}

.toggle-switch-label:focus>span {
    box-shadow: 0 0 2px 5px red;
}

.toggle-switch-label>span:focus {
    outline: none;
}

.toggle-switch-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.toggle-switch-inner:before,
.toggle-switch-inner:after {
    display: block;
    float: left;
    width: 50%;
    height: 30px;
    padding: 0;
    line-height: 30px;
    font-size: 11px;
    letter-spacing: 0.4px;
    color: white;
    font-weight: bold;
    box-sizing: border-box;
}

.toggle-switch-inner:before {
    content: attr(data-yes);
    text-transform: uppercase;
    padding-left: 10px;
    background-color: #15a866;
    color: #fff;
}

.toggle-switch-disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.toggle-switch-disabled:before {
    background-color: #ddd;
    cursor: not-allowed;
}

.toggle-switch-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: red;
    color: #fff;
    text-align: right;
}

.toggle-switch-switch {
    display: block;
    width: 20px;
    margin: 5px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 70px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}

.toggle-switch-checkbox:checked+.toggle-switch-label .toggle-switch-inner {
    margin-left: 0;
}

.toggle-switch-checkbox:checked+.toggle-switch-label .toggle-switch-switch {
    right: 0px;
}

.toggle-switch.small-switch {
    width: 40px;
}

.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
    content: "";
    height: 20px;
    line-height: 20px;
}

.toggle-switch.small-switch .toggle-switch-switch {
    width: 16px;
    right: 20px;
    margin: 2px;
}

@media screen and (max-width: 991px) {
    .toggle-switch {
        transform: scale(0.9);
    }
}

@media screen and (max-width: 767px) {
    .toggle-switch {
        transform: scale(0.825);
    }
}

@media screen and (max-width: 575px) {
    .toggle-switch {
        transform: scale(0.75);
    }
}
